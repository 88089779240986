import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PositionDto } from '../model/position-model';
import { Team } from '../model/enums/team';
import { getLocationValue, getTeamValue } from '../shared/utils';
import { Location } from '../model/enums/location';
import { PositionsService } from '../services/positions.service';
import { ApplicationsService } from '../services/applications.service';
import { firstValueFrom } from 'rxjs';
import { SpinnerComponent } from '../shared/spinner.component';

@Component({
  selector: 'app-position-detail',
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: './position-detail.component.html',
  styleUrl: './position-detail.component.css'
})
export class PositionDetailComponent {
  position: PositionDto;
  public hasUserApplied: boolean;
  loading: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private positionsService: PositionsService,
    private applicationService: ApplicationsService
  ) {
    this.route.params.subscribe(params => {
      const id = params['id'];

      if (id) {
        this.positionsService.getPosition$(id).subscribe((p) => {
          this.position = p;
          this.checkHasUserApplied(p);
        });
      }
    });
  }

  checkHasUserApplied(position: PositionDto) {    
    if (position) {
      
      this.applicationService.hasUserApplied$(position.id).subscribe(r => {
        this.hasUserApplied = r;       
        
        this.loading = false;        
      }
      );
    }
  }


  getTeamValue(enumKey: string): Team {
    return getTeamValue(enumKey);
  }

  getLocationValue(enumKey: string): Location {
    return getLocationValue(enumKey);
  }

  apply() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        position: JSON.stringify(this.position)
      }
    }
    this.router.navigate(['/applications', 'apply', this.position.id], navigationExtras);

  }
}
