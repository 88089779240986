<div class="form-container">
  <div class="progress-bar-container">
    <ul class="progressbar">
      <li [ngClass]="{ active: currentStep === 1 }">Personal Details</li>
      <li [ngClass]="{ active: currentStep === 2 }">Questions</li>
      <li [ngClass]="{ active: currentStep === 3 }">Submit</li>
    </ul>
  </div>

  <form [formGroup]="applicationForm" (ngSubmit)="onSubmit()">
    <!-- Step 1: Personal Details -->
    <div *ngIf="currentStep === 1" class="card-layout">
      <div class="e-card-content">
        <div class="form-group">
          <label for="availability" class="control-label">Availability*</label>
          <input
            type="text"
            class="form-control"
            id="availability"
            formControlName="availability"
            placeholder="When can you start?"
          />
        </div>
        <div class="form-group">
          <label for="expectedSalary" class="control-label"
            >Expected Salary*</label
          >
          <input
            type="text"
            class="form-control"
            id="expectedSalary"
            formControlName="expectedSalary"
            [placeholder]="
              'Expected Salary per year in ' +
              (position.salaryCurrency || 'USD')
            "
          />
        </div>
        <div class="form-group">
          <label for="mostRecentCompany" class="control-label"
            >Most Recent Company*</label
          >
          <input
            type="text"
            class="form-control"
            id="mostRecentCompany"
            formControlName="mostRecentCompany"
            [placeholder]="'Most Recent Company'"
          />
        </div>
        <div class="form-group">
          <label for="mostRecentJobTitle" class="control-label"
            >Most Recent Job Title*</label
          >
          <input
            type="text"
            class="form-control"
            id="mostRecentJobTitle"
            formControlName="mostRecentJobTitle"
            [placeholder]="'Most Recent Job Title'"
          />
        </div>

        <div class="form-group">
          <label class="control-label">How did you hear about us?*</label>
          <div *ngFor="let source of referralSources" class="radio-group">
            <input
              type="radio"
              formControlName="referralSource"
              [value]="source.value"
              id="{{ source.value }}"
            />
            <label for="{{ source.value }}" class="radio-label">{{
              source.label
            }}</label>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label"
            >Would you require visa sponsorship in order to work in
            {{ position.location }}?*</label
          >
          <div class="radio-group">
            <input
              type="radio"
              formControlName="immigration"
              value="true"
              id="visaYes"
            />
            <label for="visaYes" class="radio-label">Yes</label>
            <input
              type="radio"
              formControlName="immigration"
              value="false"
              id="visaNo"
            />
            <label for="visaNo" class="radio-label">No</label>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label">Upload supporting documents</label>
          <div>
            <label for="cvFile">CV *</label>
            <ejs-uploader
              id="cvFile"
              formControlName="cvFile"
              (selected)="setFileInForm('cvFile', $event)"
              allowedExtensions=".pdf"
            ></ejs-uploader>
          </div>
          <div *ngFor="let doc of additionalDocuments; let i = index">
            <label for="additionalDoc{{ i + 1 }}"
              >Additional Document {{ i + 1 }}</label
            >
            <ejs-uploader
              id="additionalDoc{{ i + 1 }}"
              [formControlName]="'additionalDoc' + (i + 1)"
              (selected)="setFileInForm('additionalDoc' + (i + 1), $event)"
              allowedExtensions=".pdf"
            ></ejs-uploader>
          </div>
        </div>
        <div class="form-group-buttons">
          <button
            type="button"
            class="btn-primary"
            (click)="nextStep()"
            [disabled]="!applicationForm.valid"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <!-- Step 2: Questions -->
    <div *ngIf="currentStep === 2" class="card-layout">
      <div class="e-card-content">
        <div formArrayName="answers">
          <div
            *ngFor="let question of position.questions; let i = index"
            class="form-group"
          >
            <app-question-answer
              [aggregateInterface]="getAggregateInterface(i)"
            ></app-question-answer>
          </div>
        </div>
        <div class="form-group-buttons">
          <button type="button" class="btn-primary" (click)="prevStep()">
            Previous
          </button>
          <button
            type="button"
            class="btn-primary"
            (click)="nextStep()"
            [disabled]="!applicationForm.valid"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <!-- Step 3: Submit -->
    <div *ngIf="currentStep === 3" class="card-layout">
      <div class="e-card-content">
        <h3>Review Your Information</h3>
        <div class="review-group">
          <p>
            <strong>Availability:</strong>
            {{ applicationForm.get("availability")?.value }}
          </p>
          <p>
            <strong>Expected Salary:</strong>
            {{ applicationForm.get("expectedSalary")?.value }}
          </p>
          <p>
            <strong>How did you hear about us:</strong>
            {{
              getReferralSourceLabel(
                applicationForm.get("referralSource")?.value
              )
            }}
          </p>
          <p>
            <strong>Visa Sponsorship:</strong>
            {{ applicationForm.get("immigration")?.value }}
          </p>
        </div>
        <div class="review-group" *ngIf="getQuestionsLength() > 0">
          <h4>Questions and Answers</h4>
          <div *ngFor="let question of position.questions; let i = index">
            <p [innerHTML]="question.question"></p>
            <p
              [innerHTML]="
                applicationForm.get('answers')?.get(i.toString())?.value.answer
              "
            ></p>
          </div>
        </div>
        <div class="review-group">
          <h4>Uploaded Files</h4>
          <p>
            <strong>CV:</strong>
            {{ getUploadedFileName("cvFile") }}
          </p>
          <div *ngFor="let doc of additionalDocuments; let i = index">
            <p>
              <strong>Additional Document {{ i + 1 }}:</strong>
              {{ getUploadedFileName("additionalDoc" + (i + 1)) }}
            </p>
          </div>
        </div>
        <div class="form-group-buttons">
          <button type="button" class="btn-primary" (click)="prevStep()">
            Previous
          </button>
          <button type="button" class="btn-primary" (click)="onSubmit()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
