import { Component, Input } from '@angular/core';
import { PositionDto } from '../model/position-model';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Team } from '../model/enums/team';
import { getTeamValue, getJobTypeValue, getLocationValue } from '../shared/utils';
import { Location } from '../model/enums/location';
import { JobType } from '../model/enums/job-type';
import { NavigationExtras, Router } from '@angular/router';
@Component({
  selector: 'app-position-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './position-card.component.html',
  styleUrl: './position-card.component.css',
  providers: [BrowserModule]
})
export class PositionCardComponent {
  @Input() position: PositionDto;

  constructor(private router: Router,
  ) { }

  getTeamValue(enumKey: string): Team {
    return getTeamValue(enumKey);
  }

  getLocationValue(enumKey: string): Location {
    return getLocationValue(enumKey);
  }

  getJobTypeValue(enumKey: string): JobType {
    return getJobTypeValue(enumKey);
  }

  getFormattedDeadline(deadline: Date): string {
    const deadlineDate = new Date(deadline);
    const targetDate = new Date('0001-01-01T00:00:00+00:00');

    const deadlineTime = deadlineDate.getTime();
    const targetTime = targetDate.getTime();

    if (deadlineTime === targetTime) {
      return 'Rolling Basis';
    }

    const date = new Date(deadline);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  navigateToPositionDetail(position: PositionDto) {
    this.router.navigate(['/positions', 'details', position.id]);
  }
}
