import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { PositionDto } from '../model/position-model';
import { CreateApplicationFormConfig } from '../model/form-configs/create-application-form-config';
import { AnswerFormConfig } from '../model/form-configs/answer-form-config';
import { ApplicationDto } from '../model/application-model';
import { CreateApplicationDto } from '../model/create-application-model';
import { AnswerDto } from '../model/answer-model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationFormService {
  constructor(private fb: FormBuilder) { }

  createForm(): FormGroup<CreateApplicationFormConfig> {
    return this.fb.group<CreateApplicationFormConfig>({
      azureB2CUserEmail: new FormControl(null),
      source: new FormControl(null),
      positionId: new FormControl(null),
      answers: this.fb.array([
        this.fb.group<AnswerFormConfig>({
          questionId: new FormControl(null),
          answer: new FormControl(null)
        })
      ]),
      availability: new FormControl(null, Validators.required),
      expectedSalary: new FormControl(null, Validators.required),
      referralSource: new FormControl(null, Validators.required), 
      cvFile: new FormControl(null, Validators.required),
      additionalDoc1: new FormControl(null),
      additionalDoc2: new FormControl(null),
      additionalDoc3: new FormControl(null),
      immigration: new FormControl(null, Validators.required),
      mostRecentCompany: new FormControl(null, Validators.required),
      mostRecentJobTitle: new FormControl(null, Validators.required)
    });
  }

  initializeForm(form: FormGroup, applicationData: CreateApplicationDto) {
    form.patchValue({
      azureB2CUserEmail: applicationData.azureB2CUserEmail,
      source: applicationData.source,
      availability: applicationData.availability,
      expectedSalary: applicationData.expectedSalary,
      referralSource: applicationData.referralSource,
    });

    const answersFormArray = form.get('answers') as FormArray;
    answersFormArray.clear();
    if (applicationData.answers) {
      applicationData?.answers.forEach((answer: AnswerDto) => {
        answersFormArray.push(this.fb.group<AnswerFormConfig>({
          answer: new FormControl(answer.answer, Validators.required),
          questionId: new FormControl(answer.questionId)
        }));
      });
    }
  }

  reset(form: FormGroup) {
    form.reset();
  }
}
