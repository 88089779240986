<div *ngIf="loading; else content">
  <app-spinner></app-spinner>
</div>
<ng-template #content>
  <div class="container-fluid d-flex flex-column" *ngIf="position">
    <!-- Header Section -->

    <div class="header-section background-cigp text-white p-4 rounded mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <div class="">
          <h1>{{ position.jobTitle }}</h1>
          <p class="lead">
            {{ getTeamValue(position.team) }} |
            {{ getLocationValue(position.location) }}
          </p>
        </div>
        <div>
          <div *ngIf="position && !hasUserApplied; else alreadyApplied">
            <button class="btn btn-secondary" (click)="apply()">Apply</button>
          </div>
          <ng-template #alreadyApplied>
            <p>You have already applied for this position</p>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- Position Details Section -->
    <div class="details-section bg-white p-4 rounded shadow">
      <h3>Position Description</h3>
      <div [innerHTML]="position.description"></div>

      <h3>Requirements</h3>
      <div [innerHTML]="position.requirements"></div> 
    </div>

    <!-- Apply Button at the Bottom -->
    <div class="text-end mt-4">
      <div *ngIf="!hasUserApplied; else alreadyApplied">
        <button class="btn btn-secondary" (click)="apply()">Apply</button>
      </div>
      <ng-template #alreadyApplied>
        <p>You have already applied for this position</p>
      </ng-template>
    </div>
  </div>
</ng-template>
