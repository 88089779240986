<div class="header-container">
  <div class="logo-container">
    <a class="logo" [routerLink]="['/']">
      <img src="assets/cigp-logo-black.svg" />
    </a>
  </div>
  <ul class="navbar-nav d-flex ms-auto align-items-flex-end me-4" *ngIf="loginDisplay">
    <of-applications-nav-menu></of-applications-nav-menu>
  </ul>

  <div class="actions-container">
    <div *ngIf="loginDisplay; else loginButton" class="user-action">
      <div class="dropdown">
        <button
          class="btn btn-primary dropdown-toggle"
          type="button"
          id="userDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ getUsername() }}
        </button>
        <ul class="dropdown-menu" aria-labelledby="userDropdown">
          <li>
            <a class="dropdown-item" (click)="editProfile()">Edit Profile</a>
          </li>
          <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
        </ul>
      </div>
    </div>
    <ng-template #loginButton>
      <button class="btn btn-primary" (click)="loginRedirect()">LOG IN</button>
    </ng-template>
  </div>
</div>
