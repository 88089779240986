import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable, throwError, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { PositionDto } from '../model/position-model';
import { showErrorDialog } from '../shared/utils';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PositionsService {
  private apiEndpoint = `${environment.apiConfig.uri}/api/positions`;
  private refreshTrigger = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private storage: LocalStorageService,
  ) { }

  public getPosition$(id: string): Observable<PositionDto> {

    return this.http.get<PositionDto>(`${this.apiEndpoint}/public/${id}`).pipe(
        tap((res) => this.storage.set(`position_${id}`, res)),
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  getAllPositionsPublic$(): Observable<PositionDto[]> {

    return this.refreshTrigger.pipe(
        switchMap(() => {
          return this.http.get<PositionDto[]>(`${environment.apiConfig.uri}/positions-public`).pipe(
            tap((res) => {
              this.storage.set('positions', res || []);
            }),
            catchError((error: any) => {

              this.logger.error(error);
              showErrorDialog(this.errorDialog, error.message);
              return throwError(() => new Error(error.message));

            })
          );
        }),
        catchError((error) => throwError(() => new Error(error.message)))
      );
  }

  total$(): Observable<number> {
    return this.getAllPositionsPublic$().pipe(
      map((positions) => positions.length),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }
}
