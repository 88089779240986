import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '../model/enums/location';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { PositionDto } from '../model/position-model';
import { Team } from '../model/enums/team';
import { JobType } from '../model/enums/job-type';

@Component({
  selector: 'app-position-filter',
  standalone: true,
  imports: [CommonModule, DropDownListModule],
  templateUrl: './position-filter.component.html',
  styleUrls: ['./position-filter.component.css']
})
export class PositionFilterComponent {
  locations = Object.values(Location);
  teams = Object.values(Team);
  jobTypes = Object.values(JobType);

  selectedPosition: string | null = null;
  selectedLocation: string | null = null;
  selectedTeam: string | null = null;
  selectedJobType: string | null = null;

  @Input() positions: PositionDto[];
  @Output() filteredPositions = new EventEmitter<PositionDto[]>();

  applyFilter(value: string, filterType: string): void {    
    let filteredPositions = this.positions.slice();

    // remove white space from enum value to match key    
    value = value?.split(/\s+/).join('');
    
    // Apply each filter based on the selected criteria
    switch (filterType) {
      case 'position':
        this.selectedPosition = value;
        break;
      case 'location':
        this.selectedLocation = value;
        break;
      case 'team':
        this.selectedTeam = value;
        break;
      case 'jobType':
        this.selectedJobType = value;
        break;
      default:
        break;
    }
  
    filteredPositions = filteredPositions.filter(
      (pos) =>
        (!this.selectedPosition || pos.jobTitle === this.selectedPosition) &&
        (!this.selectedLocation || pos.location === this.selectedLocation) &&
        (!this.selectedTeam || pos.team === this.selectedTeam) &&
        (!this.selectedJobType || pos.jobType === this.selectedJobType)
    );
  
    this.filteredPositions.emit(filteredPositions);
  }
  
}
