import { MatDialog } from '@angular/material/dialog';
import { ApiErrorDialogComponent } from './api-error-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CIGP_COLORS } from './constants';
import { ApiError } from '../model/api-errror.model';
import { Team } from '../model/enums/team';
import { Location } from '../model/enums/location';
import { JobType } from '../model/enums/job-type';

export function showErrorDialog(apiErrorDialog: MatDialog, error: ApiError): void {
  apiErrorDialog.open(ApiErrorDialogComponent, {
    width: '800px',
    data: error
  });
}

export function showSuccessSnackbar(snackBar: MatSnackBar, message: string): void {
  if (message) {
    snackBar.open(message, undefined, {
      duration: 2000
    });
  }
}

export function showInitials(value: string | null | undefined): string {
  if (!value) {
    return '';
  }

  return value
    .split(' ')
    .map((name) => name.charAt(0).toUpperCase())
    .join('');
}

export function randomCigpColor(): string {
  return CIGP_COLORS[Math.floor(Math.random() * CIGP_COLORS.length)];
}

export function getTeamValue(enumKey: string): Team {
  return Team[enumKey as keyof typeof Team];
}

export function getLocationValue(enumKey: string): Location {
  return Location[enumKey as keyof typeof Location];
}

export function getJobTypeValue(enumKey: string): JobType {
  return JobType[enumKey as keyof typeof JobType];
}