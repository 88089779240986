import { Component, OnInit } from '@angular/core';
import { PositionDto } from '../model/position-model';
import { PositionsService } from '../services/positions.service';
import { PositionFilterComponent } from '../position-filter/position-filter.component';
import { PositionCardComponent } from '../position-card/position-card.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-position-list',
  templateUrl: './position-list.component.html',
  styleUrls: ['./position-list.component.css'],
  standalone: true,
  imports: [PositionFilterComponent, PositionCardComponent, CommonModule]
})
export class PositionListComponent implements OnInit {
  positions: PositionDto[] = [];
  filteredPositions: PositionDto[] = [];

  constructor(private positionService: PositionsService) {
    this.positionService.getAllPositionsPublic$().subscribe(p => {
      this.positions = p;
      this.filteredPositions = p;
    });
  }

  onFilteredPositions(filteredPositions: PositionDto[]): void {
    this.filteredPositions = filteredPositions;
  }
  ngOnInit(): void {}
}
