import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import { EventMessage, InteractionStatus, RedirectRequest, PopupRequest, AuthenticationResult, EventType, IdTokenClaims, AccountInfo, InteractionType, PromptValue, SsoSilentRequest } from '@azure/msal-browser';
import { HeaderComponent } from './header/header.component';
import { CommonModule } from '@angular/common';
import { PositionsService } from './services/positions.service';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, CommonModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public isIframe: boolean;

  constructor() {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
