import { Routes } from '@angular/router';
import { PositionListComponent } from './position-list/position-list.component';
import { PositionDetailComponent } from './position-detail/position-detail.component';
import { MsalGuard } from '@azure/msal-angular';
import { ApplicationFormComponent } from './application/form/application-form.component';
import { ApplicationListComponent } from './application/list/application-list.component';

export const routes: Routes = [
  { path: '', component: PositionListComponent },
  {
    path: 'positions',
    children: [
      { path: '', component: PositionListComponent },
      { path: 'details/:id', component: PositionDetailComponent, canActivate: [MsalGuard] }
    ]
  },
  {
    path: 'applications',
    children: [
      { path: 'apply/:id', component: ApplicationFormComponent, canActivate: [MsalGuard] },
      { path: '', component: ApplicationListComponent, canActivate: [MsalGuard] }
    ]
  }
];
