import { Component, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, CommonModule, DecimalPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { Location } from '../../model/enums/location';
import { RecruitingMode } from '../../model/enums/recruiting-mode';
import { Team } from '../../model/enums/team';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { JobType } from '../../model/enums/job-type';
import { Observable } from 'rxjs';
import { NgbHighlight, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import {
  Column,
  FilterService,
  GridModule,
  GroupService,
  PageService,
  PageSettingsModel,
  QueryCellInfoEventArgs,
  SelectionSettingsModel,
  SortService
} from '@syncfusion/ej2-angular-grids';
import { style } from '@angular/animations';
import { ApplicationDto } from '../../model/application-model';
import { ApplicationsService } from '../../services/applications.service';
import { DropDownList, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { PositionDto } from '../../model/position-model';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { UsersService } from '../../services/users.service';
import { MsalService } from '@azure/msal-angular';
import { getLocationValue, getTeamValue } from '../../shared/utils';
import { ApplicationStatus } from '../../model/enums/application-status';

@Component({
  selector: 'list-application',
  templateUrl: './application-list.component.html',
  styleUrl: './application-list.component.scss',
  standalone: true,
  imports: [
    MatListModule,
    MatDividerModule,
    CommonModule,
    DialogModule,
  ],
  providers: [PageService, SortService, FilterService]
})
export class ApplicationListComponent implements OnInit {
  applications?: ApplicationDto[];
  @ViewChild('deleteDataDialog') deleteDataDialog: DialogComponent;

  ngOnInit(): void {
    this.service.getUserApplications$().subscribe((applications) => {
      this.applications = applications;
    });
  }

  confirmDelete(dialog: DialogComponent) {
    this.deleteMyData();
    this.closeDialog(dialog);
  }

  openDialog(dialog: DialogComponent) {
    dialog.show();
  }

  closeDialog(dialog: DialogComponent) {
    dialog.hide();
  }

  getApplicationStatusValue(enumKey: string) {
    const applicationStatusKey = ApplicationStatus[enumKey as keyof typeof ApplicationStatus];

    if (applicationStatusKey === ApplicationStatus.Submitted || 
      applicationStatusKey === ApplicationStatus.PassedHRPreScreening ||
      applicationStatusKey === ApplicationStatus.OnHold ||
      applicationStatusKey === ApplicationStatus.PotentialOffer ||
      applicationStatusKey === ApplicationStatus.OfferMade ||
      applicationStatusKey === ApplicationStatus.ProceedToInterview) {
      return ApplicationStatus.Submitted;
    } else if (applicationStatusKey === ApplicationStatus.OfferAccepted) {
      return "Successful";
    } else {
      return "Unsuccessful";
    }
  }


  deleteMyData() {
    this.usersService.deleteMyData$().subscribe(() =>
      this.authService.logoutRedirect()
    );
  }

  getTeamValue(enumKey: string) {
    return getTeamValue(enumKey);
  }

  getLocationValue(enumKey: string) {
    return getLocationValue(enumKey);
  }

  constructor(
    public service: ApplicationsService,
    private usersService: UsersService,
    private authService: MsalService,
  ) { }
}
