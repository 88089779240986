import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { ApplicationFormService } from '../../services/application-form.service';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { CommonModule } from '@angular/common';
import { SelectedEventArgs, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { PositionDto } from '../../model/position-model';
import { CreateApplicationDto } from '../../model/create-application-model';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionAnswerComponent } from '../question/question-answer.component';
import { QuestionDto } from '../../model/question-model';
import { AnswerFormConfig } from '../../model/form-configs/answer-form-config';
import { MsalService } from '@azure/msal-angular';
import { ApplicationsService } from '../../services/applications.service';
import { ProgressButtonModule } from '@syncfusion/ej2-angular-splitbuttons'
import { SpinSettingsModel } from '@syncfusion/ej2-angular-splitbuttons';
import { LoadingButtonComponent } from '../../shared/loading-button-component';
import { AnswerDto } from '../../model/answer-model';

interface t {
  question: QuestionDto;
  answer: FormGroup
}

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatePickerModule,
    CheckBoxModule,
    FormsModule,
    RichTextEditorAllModule,
    UploaderModule,
    QuestionAnswerComponent,
    ProgressButtonModule,
    LoadingButtonComponent
  ],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class ApplicationFormComponent implements OnInit {
  @Input() applicationData: CreateApplicationDto;
  @Input() isEditMode: boolean = false;
  @Output() formSubmit = new EventEmitter<FormGroup>();

  applicationForm: FormGroup;
  position: PositionDto;
  referralSources = [
    { value: 'advertisement', label: 'Advertisement' },
    { value: 'cigp', label: 'CIGP Careers Page' },
    { value: 'referral', label: 'Employee Referral' },
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'searchEngine', label: 'Search Engine' },
    { value: 'other', label: 'Other' }
  ];
  public spinSettings: SpinSettingsModel = { position: 'Right', width: 20, template: '<div class="template"></div>' };
  additionalDocuments = ['additionalDoc1', 'additionalDoc2', 'additionalDoc3'];

  public tools: object = {
    items: ['Undo', 'Redo', '|', 'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontSize', '|', 'Formats', 'Alignments', '|', 'CreateLink', 'Image', '|', 'SourceCode']
  };
  public iframe: object = { enable: true };
  public height: number = 200;
  currentStep: number = 1;

  constructor(private applicationFormService: ApplicationFormService, private route: ActivatedRoute,
    private fb: FormBuilder, private authService: MsalService, private applicationsService: ApplicationsService,
    private router: Router
  ) {
    this.applicationForm = this.applicationFormService.createForm();

    this.route.queryParams.subscribe(params => {
      const positionParam = params['position'];

      if (positionParam) {
        this.position = JSON.parse(positionParam);
      }
    });

    // set application source as candidate portal
    this.applicationForm.get('source')?.setValue('Portal')

    // set position id
    this.applicationForm.get('positionId')?.setValue(this.position.id)

    // set azure b2c email
    // the candidate can not reach this phase without inserting this information so it should be safe
    this.applicationForm.get('azureB2CUserEmail')?.setValue(this.authService.instance.getActiveAccount()?.username);


    if (this.position?.questions?.length > 0) {
      // initialize answers element for each question in position
      const answers = this.applicationForm.get('answers') as FormArray;
      answers.clear();
      this.position?.questions?.forEach(q => {

        answers.push(this.fb.group<AnswerFormConfig>({
          answer: new FormControl(null),
          questionId: new FormControl(null),
        }));
      });

    }
  }

  setFileInForm(fieldName: string, event: SelectedEventArgs) {
    var selectedFile = <File>event.filesData[0].rawFile;

    var field = this.applicationForm.get(fieldName);

    field?.setValue(selectedFile);
  }

  getUploadedFileName(controlName: string): string {
    const control = this.applicationForm.get(controlName);

    if (control && control.value && control.value) {
      return control.value.name;
    }
    return 'No file uploaded';
  }

  getAggregateInterface(i: number): t {
    var answersArray = (this.applicationForm?.get('answers') as FormArray).controls;
    var answer = answersArray.at(i) as FormGroup<AnswerFormConfig>;
    var question = this.position.questions?.at(i) as QuestionDto;

    answer.controls.questionId.setValue(question.id);

    return {
      question: question,
      answer: answer
    };
  }

  ngOnInit(): void {

    if (this.isEditMode && this.applicationData) {
      this.applicationFormService.initializeForm(this.applicationForm, this.applicationData);
    }
  }

  get questions() {
    return this.position.questions;
  }

  nextStep() {
    if (this.applicationForm.valid) {
      if (this.currentStep < 3) {
        if (this.currentStep == 1 && this.position.questions?.length == 0) {
          this.currentStep = 3;
          return;
        }
        this.currentStep++;
      }
    }
  }

  getQuestionsLength() {
    return this.position.questions?.length || 0;
  }

  get answers() {
    return this.applicationForm.get('answers') as FormArray;
  }

  getReferralSourceLabel(value: string): string {
    const source = this.referralSources.find(s => s.value === value);
    return source ? source.label : '';
  }

  prevStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  onSubmit(): void {
    if (this.applicationForm.invalid) {
      return;
    }

    const formData = new FormData();
    const fileFields = ['cvFile', 'additionalDoc1', 'additionalDoc2', 'additionalDoc3'];

    Object.keys(this.applicationForm.value).forEach((key) => {
      if (fileFields.includes(key)) {
        const file = this.applicationForm.get(key)?.value;
        if (file) {
          formData.append(key, file, file.name);
        }
      } else {
        formData.append(key, this.applicationForm.get(key)?.value);
      }
    });

    if (this.position.questions.length > 0) {

      var answers = this.applicationForm.get('answers')?.value;
      formData.append("answersDeserialized", JSON.stringify(answers));
    }
    this.applicationsService.createApplication$(formData).subscribe((app) => {
      this.router.navigate(['/positions']);
      this.applicationForm.reset();
    });
  }

}
