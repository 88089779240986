<div class="filter-content">
  <h4>Filter by</h4>

  <div class="filter-section">
    <label for="location" class="control-label required">Location</label>
    <div class="select-container">
      <ejs-dropdownlist
        id="location"
        [dataSource]="locations"
        placeholder="Select Location"
        (change)="applyFilter($event.value, 'location')"
        [showClearButton]="true"
      >
      </ejs-dropdownlist>
    </div>
  </div>

  <div class="filter-section">
    <label for="team" class="control-label required">Department</label>
    <div class="select-container">
      <ejs-dropdownlist
        id="team"
        [dataSource]="teams"
        placeholder="Department"
        (change)="applyFilter($event.value, 'team')"
          [fields]="{ text: 'value', value: 'key' }"
        [showClearButton]="true"
      >
      </ejs-dropdownlist>
    </div>
  </div>

  <div class="filter-section">
    <label for="jobType" class="control-label required">Job Type</label>
    <div class="select-container">
      <ejs-dropdownlist
        id="jobType"
        [dataSource]="jobTypes"
        placeholder="Job Type"
        (change)="applyFilter($event.value, 'jobType')"
        [showClearButton]="true"
      >
      </ejs-dropdownlist>
    </div>
  </div>
</div>
