const apiEndpoint = 'https://cigpcareersapiuat.azurewebsites.net';

export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: 'a3b5cacd-1939-4e1e-b997-d34fa9d2ef74',
    },
    redirectUri: 'https://careers-uat.cigp.app'
  },
  apiConfig: {
    scopes: [
      'https://cigpb2ccareersdev.onmicrosoft.com/1420291d-93e6-47af-b6a7-c932bddc9a38/user_impersonation',
    ],
    uri: apiEndpoint,
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1_signupsignindev',
      editProfile: 'B2C_1_edit_profile_dev',
      resetPassword: 'B2C_1_reset_password_dev',
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://cigpb2ccareersdev.b2clogin.com/cigpb2ccareersdev.onmicrosoft.com/B2C_1_signupsignindev',
      },
      resetPassword: {
        authority:
        'https://cigpb2ccareersdev.b2clogin.com/cigpb2ccareersdev.onmicrosoft.com/B2C_1_reset_password_dev',
      },
      editProfile: {
        authority:
        'https://cigpb2ccareersdev.b2clogin.com/cigpb2ccareersdev.onmicrosoft.com/B2C_1_edit_profile_dev',
      },
    },
    authorityDomain: 'cigpb2ccareersdev.b2clogin.com',
  },
};
