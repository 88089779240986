import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { forwardRef, Component, ChangeDetectionStrategy, ContentChild, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { setValue, FormBase, ComponentBase, Template, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { RichTextEditor, Toolbar, Link, Image, Audio, Video, Count, QuickToolbar, HtmlEditor, MarkdownEditor, Table, PasteCleanup, Resize, FileManager, FormatPainter, EmojiPicker } from '@syncfusion/ej2-richtexteditor';
export * from '@syncfusion/ej2-richtexteditor';
import { CommonModule } from '@angular/common';
const _c0 = ["valueTemplate"];
var RichTextEditorComponent_1;
const inputs = ['autoSaveOnIdle', 'backgroundColor', 'bulletFormatList', 'cssClass', 'editorMode', 'emojiPickerSettings', 'enableAutoUrl', 'enableHtmlEncode', 'enableHtmlSanitizer', 'enablePersistence', 'enableResize', 'enableRtl', 'enableTabKey', 'enableXhtml', 'enabled', 'enterKey', 'fileManagerSettings', 'floatingToolbarOffset', 'fontColor', 'fontFamily', 'fontSize', 'format', 'formatPainterSettings', 'formatter', 'height', 'htmlAttributes', 'iframeSettings', 'inlineMode', 'insertAudioSettings', 'insertImageSettings', 'insertVideoSettings', 'keyConfig', 'locale', 'maxLength', 'numberFormatList', 'pasteCleanupSettings', 'placeholder', 'quickToolbarSettings', 'readonly', 'saveInterval', 'shiftEnterKey', 'showCharCount', 'showTooltip', 'tableSettings', 'toolbarSettings', 'undoRedoSteps', 'undoRedoTimer', 'value', 'valueTemplate', 'width'];
const outputs = ['actionBegin', 'actionComplete', 'afterImageDelete', 'afterMediaDelete', 'afterPasteCleanup', 'beforeDialogClose', 'beforeDialogOpen', 'beforeFileUpload', 'beforeImageDrop', 'beforeImageUpload', 'beforePasteCleanup', 'beforeQuickToolbarOpen', 'beforeSanitizeHtml', 'blur', 'change', 'created', 'destroyed', 'dialogClose', 'dialogOpen', 'fileRemoving', 'fileSelected', 'fileUploadFailed', 'fileUploadSuccess', 'fileUploading', 'focus', 'imageRemoving', 'imageSelected', 'imageUploadFailed', 'imageUploadSuccess', 'imageUploading', 'quickToolbarClose', 'quickToolbarOpen', 'resizeStart', 'resizeStop', 'resizing', 'toolbarClick', 'toolbarStatusUpdate', 'updatedToolbarStatus', 'valueChange'];
const twoWays = ['value'];
/**
 * `ejs-richtexteditor` represents the Angular richtexteditor Component.
 * ```html
 * <ejs-richtexteditor></ejs-richtexteditor>
 * ```
 */
let RichTextEditorComponent = RichTextEditorComponent_1 = class RichTextEditorComponent extends RichTextEditor {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('RichTextEditorToolbar');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorLink');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorImage');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorAudio');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorVideo');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorCount');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorQuickToolbar');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorHtmlEditor');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorMarkdownEditor');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorTable');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorPasteCleanup');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorResize');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorFileManager');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorFormatPainter');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('RichTextEditorEmojiPicker');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
RichTextEditorComponent.ɵfac = function RichTextEditorComponent_Factory(t) {
  return new (t || RichTextEditorComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
RichTextEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RichTextEditorComponent,
  selectors: [["ejs-richtexteditor"]],
  contentQueries: function RichTextEditorComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.valueTemplate = _t.first);
    }
  },
  inputs: {
    autoSaveOnIdle: "autoSaveOnIdle",
    backgroundColor: "backgroundColor",
    bulletFormatList: "bulletFormatList",
    cssClass: "cssClass",
    editorMode: "editorMode",
    emojiPickerSettings: "emojiPickerSettings",
    enableAutoUrl: "enableAutoUrl",
    enableHtmlEncode: "enableHtmlEncode",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableResize: "enableResize",
    enableRtl: "enableRtl",
    enableTabKey: "enableTabKey",
    enableXhtml: "enableXhtml",
    enabled: "enabled",
    enterKey: "enterKey",
    fileManagerSettings: "fileManagerSettings",
    floatingToolbarOffset: "floatingToolbarOffset",
    fontColor: "fontColor",
    fontFamily: "fontFamily",
    fontSize: "fontSize",
    format: "format",
    formatPainterSettings: "formatPainterSettings",
    formatter: "formatter",
    height: "height",
    htmlAttributes: "htmlAttributes",
    iframeSettings: "iframeSettings",
    inlineMode: "inlineMode",
    insertAudioSettings: "insertAudioSettings",
    insertImageSettings: "insertImageSettings",
    insertVideoSettings: "insertVideoSettings",
    keyConfig: "keyConfig",
    locale: "locale",
    maxLength: "maxLength",
    numberFormatList: "numberFormatList",
    pasteCleanupSettings: "pasteCleanupSettings",
    placeholder: "placeholder",
    quickToolbarSettings: "quickToolbarSettings",
    readonly: "readonly",
    saveInterval: "saveInterval",
    shiftEnterKey: "shiftEnterKey",
    showCharCount: "showCharCount",
    showTooltip: "showTooltip",
    tableSettings: "tableSettings",
    toolbarSettings: "toolbarSettings",
    undoRedoSteps: "undoRedoSteps",
    undoRedoTimer: "undoRedoTimer",
    value: "value",
    valueTemplate: "valueTemplate",
    width: "width"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    afterImageDelete: "afterImageDelete",
    afterMediaDelete: "afterMediaDelete",
    afterPasteCleanup: "afterPasteCleanup",
    beforeDialogClose: "beforeDialogClose",
    beforeDialogOpen: "beforeDialogOpen",
    beforeFileUpload: "beforeFileUpload",
    beforeImageDrop: "beforeImageDrop",
    beforeImageUpload: "beforeImageUpload",
    beforePasteCleanup: "beforePasteCleanup",
    beforeQuickToolbarOpen: "beforeQuickToolbarOpen",
    beforeSanitizeHtml: "beforeSanitizeHtml",
    blur: "blur",
    change: "change",
    created: "created",
    destroyed: "destroyed",
    dialogClose: "dialogClose",
    dialogOpen: "dialogOpen",
    fileRemoving: "fileRemoving",
    fileSelected: "fileSelected",
    fileUploadFailed: "fileUploadFailed",
    fileUploadSuccess: "fileUploadSuccess",
    fileUploading: "fileUploading",
    focus: "focus",
    imageRemoving: "imageRemoving",
    imageSelected: "imageSelected",
    imageUploadFailed: "imageUploadFailed",
    imageUploadSuccess: "imageUploadSuccess",
    imageUploading: "imageUploading",
    quickToolbarClose: "quickToolbarClose",
    quickToolbarOpen: "quickToolbarOpen",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing",
    toolbarClick: "toolbarClick",
    toolbarStatusUpdate: "toolbarStatusUpdate",
    updatedToolbarStatus: "updatedToolbarStatus",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RichTextEditorComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function RichTextEditorComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], RichTextEditorComponent.prototype, "valueTemplate", void 0);
RichTextEditorComponent = RichTextEditorComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], RichTextEditorComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RichTextEditorComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-richtexteditor',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RichTextEditorComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    valueTemplate: [{
      type: ContentChild,
      args: ['valueTemplate']
    }]
  });
})();

/**
 * NgModule definition for the RichTextEditor component.
 */
class RichTextEditorModule {}
RichTextEditorModule.ɵfac = function RichTextEditorModule_Factory(t) {
  return new (t || RichTextEditorModule)();
};
RichTextEditorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RichTextEditorModule
});
RichTextEditorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RichTextEditorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [RichTextEditorComponent],
      exports: [RichTextEditorComponent]
    }]
  }], null, null);
})();
const ToolbarService = {
  provide: 'RichTextEditorToolbar',
  useValue: Toolbar
};
const LinkService = {
  provide: 'RichTextEditorLink',
  useValue: Link
};
const ImageService = {
  provide: 'RichTextEditorImage',
  useValue: Image
};
const AudioService = {
  provide: 'RichTextEditorAudio',
  useValue: Audio
};
const VideoService = {
  provide: 'RichTextEditorVideo',
  useValue: Video
};
const CountService = {
  provide: 'RichTextEditorCount',
  useValue: Count
};
const QuickToolbarService = {
  provide: 'RichTextEditorQuickToolbar',
  useValue: QuickToolbar
};
const HtmlEditorService = {
  provide: 'RichTextEditorHtmlEditor',
  useValue: HtmlEditor
};
const MarkdownEditorService = {
  provide: 'RichTextEditorMarkdownEditor',
  useValue: MarkdownEditor
};
const TableService = {
  provide: 'RichTextEditorTable',
  useValue: Table
};
const PasteCleanupService = {
  provide: 'RichTextEditorPasteCleanup',
  useValue: PasteCleanup
};
const ResizeService = {
  provide: 'RichTextEditorResize',
  useValue: Resize
};
const FileManagerService = {
  provide: 'RichTextEditorFileManager',
  useValue: FileManager
};
const FormatPainterService = {
  provide: 'RichTextEditorFormatPainter',
  useValue: FormatPainter
};
const EmojiPickerService = {
  provide: 'RichTextEditorEmojiPicker',
  useValue: EmojiPicker
};
/**
 * NgModule definition for the RichTextEditor component with providers.
 */
class RichTextEditorAllModule {}
RichTextEditorAllModule.ɵfac = function RichTextEditorAllModule_Factory(t) {
  return new (t || RichTextEditorAllModule)();
};
RichTextEditorAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RichTextEditorAllModule
});
RichTextEditorAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ToolbarService, LinkService, ImageService, AudioService, VideoService, CountService, QuickToolbarService, HtmlEditorService, MarkdownEditorService, TableService, PasteCleanupService, ResizeService, FileManagerService, FormatPainterService, EmojiPickerService],
  imports: [[CommonModule, RichTextEditorModule], RichTextEditorModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RichTextEditorAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RichTextEditorModule],
      exports: [RichTextEditorModule],
      providers: [ToolbarService, LinkService, ImageService, AudioService, VideoService, CountService, QuickToolbarService, HtmlEditorService, MarkdownEditorService, TableService, PasteCleanupService, ResizeService, FileManagerService, FormatPainterService, EmojiPickerService]
    }]
  }], null, null);
})();

// Mapping root file for package generation

/**
 * Generated bundle index. Do not edit.
 */

export { AudioService, CountService, EmojiPickerService, FileManagerService, FormatPainterService, HtmlEditorService, ImageService, LinkService, MarkdownEditorService, PasteCleanupService, QuickToolbarService, ResizeService, RichTextEditorAllModule, RichTextEditorComponent, RichTextEditorModule, TableService, ToolbarService, VideoService };
