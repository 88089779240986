<div *ngIf="applications && applications.length > 0; else noApplications">
  <div class="application-header-container">
    <button
      class="btn btn-danger delete-data-btn"
      (click)="openDialog(deleteDataDialog)"
    >
      Delete All My Data
    </button>
  </div>

  <ejs-dialog
    #deleteDataDialog
    header="Delete All My Data"
    width="400"
    [isModal]="true"
    [visible]="false"
  >
    <ng-template #content>
      <div class="dialog-content">
        <p>
          Are you sure you want to delete all your data? This action will delete
          all associated CVs, Applications and User Data. This cannot be undone.
        </p>
        <div class="buttons">
          <button
            class="btn btn-success"
            (click)="confirmDelete(deleteDataDialog)"
          >
            Confirm
          </button>
          <button
            class="btn btn-danger"
            (click)="closeDialog(deleteDataDialog)"
          >
            Cancel
          </button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>

  <div class="application-list">
    <div class="application-card" *ngFor="let application of applications">
      <div class="application-header">
        <h3>{{ application.position.jobTitle }}</h3>
        <span class="status">{{
          getApplicationStatusValue(application.status)
        }}</span>
      </div>
      <div class="application-body">
        <p>
          <strong>Team:</strong> {{ getTeamValue(application.position.team) }}
        </p>
        <p>
          <strong>Location:</strong>
          {{ getLocationValue(application.position.location) }}
        </p>
        <p>
          <strong>Submitted:</strong>
          {{ application.created | date : "dd-MM-yyyy" }}
        </p>
      </div>
    </div>
  </div>
</div>
<ng-template #noApplications>
  <div class="no-applications">
    <p>You currently have no applications</p>
  </div>
</ng-template>
