<div class="position-card" (click)="navigateToPositionDetail(position)">
  <h3 class="job-title">{{ position.jobTitle }}</h3>
  <div class="location">
    <i class="fas fa-map-marker-alt"></i> {{ getLocationValue(position.location) }}
  </div>
  <div class="details">
    <span class="deadline">Deadline: {{ getFormattedDeadline(position.deadline) }}</span>
    <span class="type">{{ getJobTypeValue(position.jobType) }}</span>
    <span class="team">{{ getTeamValue(position.team) }}</span>
  </div>
</div>
