import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable, throwError, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { showErrorDialog } from '../shared/utils';
import { environment } from '../../environments/environment';
import { ApplicationDto } from '../model/application-model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  private apiEndpoint = `${environment.apiConfig.uri}/api/applications`;
  private refreshTrigger = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
  ) { }

  public createApplication$(application: FormData): Observable<ApplicationDto> {
    return this.http.post<ApplicationDto>(this.apiEndpoint, application).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  getUserApplications$(): Observable<ApplicationDto[]> {
    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<ApplicationDto[]>(this.apiEndpoint + "/b2c-user-applications").pipe(
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
      })
    );
  }

  public hasUserApplied$(positionId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiEndpoint}/has-user-applied/${positionId}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }
}
