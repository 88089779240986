import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PositionDto } from '../model/position-model';
import { Team } from '../model/enums/team';
import { getLocationValue, getTeamValue } from '../shared/utils';
import { Location } from '../model/enums/location';
import { PositionsService } from '../services/positions.service';
import { SpinnerComponent } from '../shared/spinner.component';

@Component({
  selector: 'app-position-detail',
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: './position-detail.component.html',
  styleUrl: './position-detail.component.css',
})
export class PositionDetailComponent {
  position: PositionDto;
  public hasUserApplied: boolean;
  loading: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private positionsService: PositionsService
  ) {
    this.route.params.subscribe((params) => {
      const id = params['id'];
      if (id) {
        this.positionsService.getPosition$(id).subscribe((p) => {
          this.position = p;
          this.loading = false;
        });
      }
    });
  }

  getTeamValue(enumKey: string): Team {
    return getTeamValue(enumKey);
  }

  getLocationValue(enumKey: string): Location {
    return getLocationValue(enumKey);
  }

  apply() {
    this.router.navigate(['/applications', 'apply', this.position.id]);
  }
}
