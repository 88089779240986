export enum Team {
  AccountingAndFinance = 'Accounting & Finance',
  Administration = 'Administration',
  AssetManagement = 'Asset Management',
  ChiefOfStaff = 'Chief of Staff',
  Compliance = 'Compliance',
  HR = 'HR',
  InvestmentBanking = 'Investment Banking',
  IT = 'IT',
  RelationshipManager = 'Relationship Manager',
  ResearchWorkingGroup = 'Research Working Group',
  TradingAndOperations = 'Trading & Operations',
  WealthManagement = 'Wealth Management'
}

