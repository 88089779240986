import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgStyle } from '@angular/common';
import { ProgressButtonModule } from '@syncfusion/ej2-angular-splitbuttons';

@Component({
  selector: 'loading-button',
  standalone: true,
  imports: [NgStyle, ProgressButtonModule],
  template: `
    <button ejs-progressbutton 
            type="submit" 
            [ngStyle]="buttonStyle" 
            [content]="buttonText" 
            [spinSettings]="spinSettings" 
            [disabled]="disabled" 
            (click)="handleClick()">
    </button>
  `,
})
export class LoadingButtonComponent {
  @Input() buttonText: string = 'Submit';
  @Input() disabled: boolean = false;
  @Input() buttonStyle: { [key: string]: string } = {
    color: 'white',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '4px',
    cursor: 'pointer',
  };
  @Output() onSubmit = new EventEmitter<void>();

  public spinSettings = { position: 'Right', width: 20, template: '<div class="template"></div>' };

  handleClick() {
    if (!this.disabled) {
      this.onSubmit.emit();
    }
  }
}
