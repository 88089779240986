<div class="container">
  <div class="filter-container">
    <app-position-filter
      [positions]="positions"
      (filteredPositions)="onFilteredPositions($event)"
    ></app-position-filter>
  </div>
  <div
    class="position-list-container"
    *ngIf="positions && positions.length > 0; else noPositions"
  >
    <div *ngFor="let position of filteredPositions" class="position-list">
      <app-position-card [position]="position"></app-position-card>
    </div>
  </div>
</div>

<ng-template #noPositions>
  <div class="no-positions">
    <p>Currently, there are no open positions</p>
  </div>
</ng-template>
