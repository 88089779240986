import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType, AuthenticationResult, IdTokenClaims, RedirectRequest, PromptValue, AccountInfo, SsoSilentRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { PositionsService } from '../services/positions.service';
import { ItemModel } from '@syncfusion/ej2-angular-splitbuttons';
import { ApplicationsNavMenuComponent } from "./application-nav.component";

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        RouterModule,
        MatButtonModule,
        CommonModule,
        ApplicationsNavMenuComponent
    ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();
  public production = environment.production;
  loginDisplay = false;
  public items: ItemModel[] = [
    { text: 'Edit Profile', iconCss: 'e-icons e-edit', id: 'edit-profile' },
    { text: 'Logout', iconCss: 'e-icons e-logout', id: 'logout' }
  ];

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private positionsService: PositionsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isIframe = window !== window.parent && !window.opener;
    await this.authService.instance.initialize();

    this.authService.handleRedirectObservable().subscribe();

    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        const idToken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (idToken.acr === environment.b2cPolicies.names.signUpSignIn || idToken.tfp === environment.b2cPolicies.names.signUpSignIn) {
          this.authService.instance.setActiveAccount(payload.account);
        }

        if (idToken.acr === environment.b2cPolicies.names.editProfile || idToken.tfp === environment.b2cPolicies.names.editProfile) {
          const originalSignInAccount = this.authService.instance.getAllAccounts()
            .find((account: AccountInfo) =>
              account.idTokenClaims?.oid === idToken.oid &&
              account.idTokenClaims?.sub === idToken.sub &&
              ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === environment.b2cPolicies.names.signUpSignIn ||
                (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === environment.b2cPolicies.names.signUpSignIn)
            );

          if (originalSignInAccount) {
            const signUpSignInFlowRequest: SsoSilentRequest = {
              authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
              account: originalSignInAccount
            };
            this.authService.ssoSilent(signUpSignInFlowRequest);
          }
        }

        if (idToken.acr === environment.b2cPolicies.names.resetPassword || idToken.tfp === environment.b2cPolicies.names.resetPassword) {
          const signUpSignInFlowRequest: RedirectRequest = {
            authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [...environment.apiConfig.scopes],
            prompt: PromptValue.LOGIN
          };
          this.login(signUpSignInFlowRequest);
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_FAILURE ||
          msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          const resetPasswordFlowRequest: RedirectRequest = {
            authority: environment.b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };
          this.login(resetPasswordFlowRequest);
        }
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
    }
  }

  getUsername() {
    return this.authService.instance.getActiveAccount()?.name;
  }

  public onSelect(args: any): void {
    if (args.item.id === 'edit-profile') {
      this.editProfile();
    } else if (args.item.id === 'logout') {
      this.logout();
    }
  }

  loginRedirect() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.authService.loginRedirect();
      });
  }

  login(userFlowRequest?: RedirectRequest) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.authService.loginRedirect(userFlowRequest);
      });
  }

  logout() {
    this.authService.logoutRedirect();
  }

  editProfile() {
    this.positionsService.getAllPositionsPublic$().subscribe();
    const editProfileFlowRequest: RedirectRequest = {
      authority: environment.b2cPolicies.authorities.editProfile.authority,
      scopes: [],
    };
    this.login(editProfileFlowRequest);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
