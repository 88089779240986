export enum ApplicationStatus {
  OfferAccepted = 'Offer Accepted',
  OfferMade = 'Offer Made',
  OfferDeclined = 'Offer Declined',
  OnHold = 'On Hold',
  PassedHRPreScreening = 'Passed HR PreScreening',
  PotentialOffer = 'Potential Offer',
  ProceedToInterview = 'Proceed To Interview',
  Rejected = 'Rejected',
  Submitted = 'Submitted',
}
