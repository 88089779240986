import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'of-applications-nav-menu',
  standalone: true,
  imports: [RouterModule],
  template: `
    <div>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Applications
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['/applications/']"><i class="bi bi-list"></i> My Applications</a>
          </li>
        </ul>
      </li>
</div>
  `
})
export class ApplicationsNavMenuComponent {}
